import React from 'react'
import {
  Button,
  Dialog,
  DialogTrigger,
  Heading,
  OverlayArrow,
  Popover,
} from 'react-aria-components'

export const CardLike = (props: {
  onClick: () => void
  children: React.ReactNode
}) => {
  return (
    <div
      onClick={props.onClick}
      role={'button'}
      className={
        'p-1 bg-white border cursor-pointer hover:shadow-lg rounded-sm flex justify-center items-center'
      }
    >
      {props.children}
    </div>
  )
}

export const CardWithPopover = (props: {
  title: string
  children: React.ReactNode
  sourceName: string
  documentId?: string
  highlighted?: boolean
}) => {
  return (
    <DialogTrigger>
      <Button
        className={
          'p-1 bg-white border cursor-pointer hover:shadow-lg rounded-sm text-left focus:border-blue-400 ' +
          (props.highlighted ? '' : 'opacity-30')
        }
        id={'source_' + props.documentId}
      >
        <div className="flex row items-center gap-2">
          <div>
            <img
              src={'/icons/' + (props.sourceName || '').toLowerCase() + '.png'}
              alt={''}
              className="w-6 h-6"
            />
          </div>
          <div className={'flex-1'}>
            <h3>{props.title}</h3>
            <div
              className={
                'text-gray-500 text-uppercase text-sm flex justify-between gap-2'
              }
            >
              <div>{props.sourceName}</div>
              <div className={'text-black'}>[{props.documentId}]</div>
            </div>
          </div>
        </div>
      </Button>
      <Popover>
        <OverlayArrow>
          <svg width={12} height={12} viewBox="0 0 12 12">
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
        <Dialog
          className={
            'max-w-xl max-h-64 overflow-y-auto bg-white border-2 border-gray-300 rounded-lg p-4 shadow-2xl'
          }
        >
          <Heading slot="title">{props.title}</Heading>
          {props.children}
        </Dialog>
      </Popover>
    </DialogTrigger>
  )
}
