import React, { useEffect, useState } from 'react'
import './App.css'
import { useMsal } from '@azure/msal-react'
import { apiSearchScopeAction } from './authConfig'

import UserIcon from '@mui/icons-material/AccountCircle'
import useCustomerConfig from './hooks/useCustomerConfig'
import AddCommentIcon from '@mui/icons-material/AddComment'
import { Chat } from './components/Chat'
import { FeedbackExplorer } from './components/FeedbackExplorer'
import { Message } from './types'
import { ChatInputBox } from './components/ChatInputBox'
import { GiveFeedback } from './components/GiveFeedback'
import { Button } from './components/Buttons'

const UserIconAndName = () => {
  const { instance, accounts } = useMsal()
  const handleLogin = (loginType: any) => {
    if (loginType === 'popup') {
      instance.loginPopup().catch((e) => {
        console.error(e)
      })
    } else {
      instance.loginRedirect().catch((e) => {
        console.error(e)
      })
    }
  }

  const handleLogout = () => {
    const answer = window.confirm('Are you sure you want to sign out?')
    if (!answer) return
    instance.logout().catch((e) => {
      console.error(e)
    })
  }

  return (
    <div
      className="flex items-center gap-2 flex-col p-4"
      tabIndex={0}
      role={'button'}
      onClick={handleLogin}
    >
      <UserIcon style={{ fontSize: 40 }} />
      {accounts && accounts.length ? (
        <span>{accounts[0].name}</span>
      ) : (
        <span>Logg in</span>
      )}
    </div>
  )
}

const createNewChatbotThread = async (
  bearerToken: string,
  chatServer: string,
) => {
  const response = await fetch(chatServer + '/threads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })
  return (await response.json()).id as string
}

const postChatMsgToThreadAndStreamResponse = async function* (
  bearerToken: string,
  chatServer: string,
  threadId: string,
  messageText: string,
) {
  const newChatResponse = await fetch(
    chatServer + '/threads/' + threadId + '/messages',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
      body: JSON.stringify({
        message: messageText,
      }),
    },
  )

  // It responds as a stream:
  const reader = newChatResponse.body?.getReader()
  let result = await reader?.read()
  while (!result?.done) {
    const text = new TextDecoder().decode(result?.value)
    const jsonObjects = text.trim().split('\n').filter(Boolean)
    for (const jsonObject of jsonObjects) {
      yield JSON.parse(jsonObject)
    }
    result = await reader?.read()
  }
}
const SnowchatHeaderWithLogo = () => {
  const customerConfig = useCustomerConfig()

  return (
    <header
      className={`${customerConfig.theme.header} ${customerConfig.theme.headerText} border-b-2`}
    >
      <div
        className={`m-auto max-w-5xl flex items-center gap-4 justify-between`}
      >
        <img src={customerConfig.logo} className="h-14 ml-2" alt="logo" />
        <div className="hidden md:block">
          <div className="flex flex-col gap-2 items-center">
            <h1 className="text-5xl font-bold m-0">{customerConfig.header}</h1>
            <span>{customerConfig.subheader}</span>
          </div>
        </div>
        <UserIconAndName />
      </div>
    </header>
  )
}

const getAccessTokenForSemanticApi = async (accounts: any, instance: any) => {
  const accessTokenRequest = {
    scopes: [apiSearchScopeAction],
    account: accounts[0],
  }
  try {
    const accessTokenResponse =
      await instance.acquireTokenSilent(accessTokenRequest)
    return accessTokenResponse.accessToken
  } catch (error) {
    console.log('Silent token acquisition fails. Acquiring token using popup')
    try {
      const accessTokenResponse =
        await instance.acquireTokenRedirect(accessTokenRequest)
      return accessTokenResponse.accessToken
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

const App = () => {
  const { instance, accounts } = useMsal()
  const customerConfig = useCustomerConfig()
  const [threadId, setThreadId] = useState<string | null>(null)
  const [conversation, setConversation] = useState<Message[]>([])
  const [botIsTyping, setBotIsTyping] = useState(false)

  const startNewConversationThread = async () => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    const newThreadId = await createNewChatbotThread(
      token,
      customerConfig.chatServer,
    )
    setThreadId(newThreadId)
    setConversation([])
  }

  useEffect(() => {
    if (accounts.length > 0 && instance && threadId === null) {
      const timer = setTimeout(startNewConversationThread, 250)
      return () => clearTimeout(timer)
    }
  }, [accounts, instance, threadId])

  const onFeedbackSubmit = async (grade: number, comment: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    if (!token) {
      console.error('Failed to get access token')
      return
    } else {
      const res = await fetch(customerConfig.chatServer + '/feedback', {
        method: 'POST',
        body: JSON.stringify({ grade, comment, conversation }),
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      })
    }
  }

  const onSubmitChatMessage = async (messageText: string) => {
    const token = await getAccessTokenForSemanticApi(accounts, instance)
    const newConversation = [
      ...conversation,
      { role: 'user', text: messageText } as Message,
    ]
    setConversation(newConversation)
    if (!token) {
      console.error('Failed to get access token')
      return
    } else if (!threadId) {
      console.error('No thread id')
      return
    } else {
      const responseStream = postChatMsgToThreadAndStreamResponse(
        token,
        customerConfig.chatServer,
        threadId,
        messageText,
      )
      setBotIsTyping(true)
      for await (const chunk of responseStream) {
        if (chunk.answer) {
          setConversation((conversation) => {
            const last = conversation[conversation.length - 1]
            if (last.role === 'assistant') {
              return [
                ...conversation.slice(0, -1),
                { ...last, text: last.text + chunk.answer },
              ]
            } else {
              return [
                ...conversation,
                { role: 'assistant', text: chunk.answer },
              ]
            }
          })
        }
        if (chunk.document) {
          setConversation((conversation) => {
            const last = conversation[conversation.length - 1]
            if (last.role === 'assistant') {
              return [
                ...conversation.slice(0, -1),
                {
                  ...last,
                  documents: [...(last.documents || []), chunk.document],
                },
              ]
            } else {
              return [
                ...conversation,
                { role: 'assistant', text: '', documents: [chunk.document] },
              ]
            }
          })
        }
      }
      setBotIsTyping(false)
    }
  }
  const conversationEmpty = conversation.length === 0
  const waitingForBotToRespond =
    conversation.length > 0 &&
    conversation[conversation.length - 1].role === 'user'

  return (
    <div className="h-screen flex flex-col bg-gray-50">
      <SnowchatHeaderWithLogo />
      <div className={'flex justify-center gap-2 p-1'}>
        {!conversationEmpty && (
          <>
            <Button onClick={startNewConversationThread} variant={'primary'}>
              <AddCommentIcon />
              Ny samtale
            </Button>
            <GiveFeedback onSubmit={onFeedbackSubmit} />
          </>
        )}
        {accounts && accounts.length > 0 && (
          <FeedbackExplorer
            getToken={() => getAccessTokenForSemanticApi(accounts, instance)}
          />
        )}
      </div>
      <Chat
        conversation={conversation}
        emptyConversationPlaceholder={
          <div
            className={
              'flex-1 flex flex-col items-center text-xl text-gray-500'
            }
          >
            <p className={''}>
              <AddCommentIcon fontSize={'large'} /> Velkommen til assistenten!
              Still spørsmål i boksen nederst for å starte en samtale.
            </p>
          </div>
        }
      />

      <div className="p-2 border-t border-gray-200">
        <ChatInputBox
          onSubmit={onSubmitChatMessage}
          quickTextActions={
            conversationEmpty ? [] : customerConfig.quickTextActions
          }
          canSubmit={
            !waitingForBotToRespond && !botIsTyping && threadId !== null
          }
        />
      </div>
    </div>
  )
}

export default App
