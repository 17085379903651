import React, { useEffect, useState } from 'react'
import { Button } from './Buttons'

export const ChatInputBox = (props: {
  onSubmit: (message: string) => void
  quickTextActions?: string[]
  canSubmit: boolean
}) => {
  const [input, setInput] = useState('')
  const canSubmit = props.canSubmit && input.trim().length > 0

  // focus on the input field when input changes:
  useEffect(() => {
    if (input.length > 0) {
      document.getElementById('chat-input')?.focus()
    }
  }, [input])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault() // Prevent default form submission
    if (canSubmit) {
      props.onSubmit(input)
      setInput('')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevent the default action to avoid inserting a newline
      handleSubmit(event as unknown as React.FormEvent) // Cast the event type to match handleSubmit
    }
  }

  return (
    <div className={''}>
      <form className="flex space-x-2 max-w-5xl m-auto" onSubmit={handleSubmit}>
        <div className={'flex-1 flex flex-col h-24'}>
          {props.quickTextActions &&
            input === '' &&
            props.quickTextActions.length > 0 && (
              <div className="flex gap-2 items-center mb-1 ml-2">
                <h4 className={'text-sm'}>Forslag:</h4>
                {props.quickTextActions.map((action, index) => (
                  <Button
                    key={index}
                    size={'small'}
                    onClick={() => setInput(action)}
                  >
                    {action}
                  </Button>
                ))}
              </div>
            )}
          <textarea
            placeholder="Skriv en melding her..."
            className="flex-1 border-2 border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-300 focus:border-2 focus:shadow-md focus:bg-white focus:text-black"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            id="chat-input"
          />
        </div>
        <button
          type="submit"
          className={`text-white font-bold py-2 px-4 rounded bg-blue-900 ${canSubmit ? 'hover:bg-blue-800' : 'bg-gray-300'}`}
          disabled={!canSubmit}
        >
          Send
        </button>
      </form>
    </div>
  )
}
