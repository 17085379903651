import React, { useState } from 'react'
import Markdown from 'react-markdown'
import { CardLike, CardWithPopover } from './Card'

export type DocumentViewerProps = {
  documents: any[]
  highlightedDocumentIds: string[]
}
export const SourcesView = (props: DocumentViewerProps) => {
  const [showAll, setShowAll] = useState(false)
  return (
    <>
      <h3 className={'font-bold uppercase text-gray-600'}>Kilder</h3>
      <div className={'grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-4'}>
        {props.documents
          .filter(
            (doc, index) =>
              showAll ||
              props.highlightedDocumentIds.includes('' + doc.documentId),
          ) // Filter before mapping
          .map((doc, index) => (
            <CardWithPopover
              title={doc.title}
              key={index}
              sourceName={doc.sourceName}
              documentId={doc.documentId}
              highlighted={props.highlightedDocumentIds.includes(
                '' + doc.documentId,
              )}
            >
              <a
                href={doc.source}
                target="_blank"
                rel="noreferrer"
                className={'text-blue-800'}
              >
                {doc.source}
              </a>
              <Markdown>{doc.content}</Markdown>
            </CardWithPopover>
          ))}
        <CardLike onClick={() => setShowAll(!showAll)}>
          <span className={'text-gray-500 uppercase text-sm'}>
            {showAll ? 'Skjul øvrige kilder...' : 'Vis øvrige kilder...'}
          </span>
        </CardLike>
      </div>
    </>
  )
}
