import bakrusinfo from './bakrusinfo'
import snowchat from './snowchat'
import rusinfo from './rusinfo'

const configs = {
  bakrusinfo,
  rusinfo,
  snowchat
}

export default configs
