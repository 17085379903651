import React from 'react'

type ButtonProps = {
  onClick: () => void
  children: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'normal'
}
export const Button = ({ onClick, children, size, variant }: ButtonProps) => {
  const extraClasses =
    (size === 'small' ? 'p-1 text-sm' : size === 'large' ? 'p-3' : 'p-2') +
    ' ' +
    (variant == 'primary'
      ? ' bg-blue-900 text-white hover:bg-blue-800 border-blue-900'
      : ' bg-gray-100 text-black hover:bg-gray-200 border-dashed')
  return (
    <button
      type={'button'}
      onClick={onClick}
      className={
        'inline-flex gap-1 items-center p-1 rounded-lg border ' + extraClasses
      }
    >
      {children}
    </button>
  )
}
