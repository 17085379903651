import Markdown from 'react-markdown'
import React, { useState } from 'react'
import { Check, CopyAll } from '@mui/icons-material'
import { Message } from '../types'
import { SourcesView } from './SourcesView'

type Props = {
  conversation: Message[]
  emptyConversationPlaceholder: React.ReactNode
}
export const Chat = ({ conversation, emptyConversationPlaceholder }: Props) => {
  const waitingForBotToRespond =
    conversation.length > 0 &&
    conversation[conversation.length - 1].role === 'user'
  const conversationEmpty = conversation.length === 0

  return (
    <div
      className={
        'overflow-y-auto flex-1 bg-gray-50 flex flex-col items-center ' +
        (conversationEmpty ? 'justify-center' : '')
      }
    >
      <div className="p-4 flex flex-col gap-4 lg:max-w-5xl w-full">
        {conversationEmpty && emptyConversationPlaceholder}
        {conversation.map((message, index) => {
          let text = message.text
          const mentionedSourcesInText = text.match(/\[(\d+)\]/g)
          const idsOfMentionedSources = mentionedSourcesInText?.map((s) =>
            s.replace(/\[|\]/g, ''),
          )
          const textWithoutSources = text.replace(/\[(\d+)\]/g, '')
          text =
            message.role === 'assistant'
              ? text.replace(/\[(\d+)\]/g, (match) => {
                  const id = match.replace(/\[|\]/g, '')
                  return `[${id}](#source_${id})` // This is a markdown link
                })
              : text
          const CustomAnchor = (props: any) => {
            return props.href.match(/^https?:\/\//) ? (
              <a
                {...props}
                href={props.href}
                target="_blank"
                rel="noreferrer"
                className={'text-blue-800'}
              >
                {props.children}
              </a>
            ) : (
              <a
                {...props}
                className={
                  'text-fuchsia-800 p-1 no-underline align-top text-xs'
                }
              >
                {props.children}
              </a>
            )
          }
          return (
            <div key={index} className={'flex flex-col gap-2'}>
              <ChatBubble
                key={index}
                color={message.role === 'assistant' ? 'fancy' : 'neutral'}
              >
                <Markdown
                  components={{
                    a: CustomAnchor,
                  }}
                >
                  {text}
                </Markdown>
                {message.role === 'assistant' && (
                  <div className={'mt-4 p-2 flex gap-2'}>
                    <CopyResponseButton text={textWithoutSources} />
                  </div>
                )}
              </ChatBubble>
              {message.documents && (
                <SourcesView
                  documents={message.documents}
                  highlightedDocumentIds={idsOfMentionedSources || []}
                />
              )}
            </div>
          )
        })}

        {waitingForBotToRespond && (
          <ChatBubble color={'fancy'}>
            <div className={'flex gap-3 items-center'}>
              <BotThinkingSpinner />
              <div>Assistenten tenker...</div>
            </div>
          </ChatBubble>
        )}
      </div>
    </div>
  )
}

const BotThinkingSpinner = () => (
  <div
    className={
      'animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-900'
    }
  ></div>
)

const CopyResponseButton = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyResponse = () => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
    })
  }

  return (
    <button
      onClick={copyResponse}
      className={`flex items-center gap-2 ${isCopied ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'} p-2 rounded-lg border-2 ${isCopied ? '' : 'border-dashed hover:bg-gray-200'}`}
    >
      {isCopied ? 'Kopiert!' : 'Kopier svar'}
      {isCopied ? <Check /> : <CopyAll />}
    </button>
  )
}

type ChatBubbleProps = {
  children: React.ReactNode
  color: 'neutral' | 'fancy'
}
export const ChatBubble = (p: ChatBubbleProps) => {
  const bubbleClasses = {
    // User message must pad to the right, and have a chat bubble arrow on the right
    neutral: 'bg-gray-300 text-black ml-auto rounded-r-lg pr-4',
    fancy: `mr-auto fancy-pink-background`,
  }
  return (
    <div
      className={`font-poppins prose lg:prose prose-stone p-3 rounded-lg ${bubbleClasses[p.color]}`}
    >
      {p.children}
    </div>
  )
}
